<template>
    <v-container class="my-3">
        <AdBTWR v-if="!user.isAdFree"/>
        <v-row justify="center">
            <v-col cols="12" sm="10">
                <h1 class="display-1 mb-5">Privacy Policy</h1>

                <p class="text-justify">
                    This privacy policy has been compiled to better serve those who are concerned with how their
                    'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and
                    information security, is information that can be used on its own or with other information to
                    identify, contact, or locate a single person, or to identify an individual in context. Please read
                    our privacy policy carefully to get a clear understanding of how we collect, use, protect or
                    otherwise handle your Personally Identifiable Information in accordance with our website.
                </p>

                <h2 class="title font-weight-medium">What personal information do we collect from the people that visit
                    our blog, website or app?</h2>

                <p class="text-justify">
                    When ordering or registering on our site, as appropriate, you may be asked to enter your Email
                    address, FCC Callsign or other details to help you with your experience.
                </p>

                <h2 class="title font-weight-medium">When do we collect information?</h2>
                <p class="text-justify">
                    We collect information from you when you register on our site, fill out a form or enter information
                    on our site.
                </p>

                <h2 class="title font-weight-medium">How do we use your information?</h2>
                <p class="text-justify">
                    We may use the information we collect from you when you register, make a purchase, sign up for our
                    newsletter, respond to a survey or marketing communication, surf the website, or use certain other
                    site features in the following ways:
                </p>

                <ul class="mb-5">
                    <li class="text-justify">
                        To personalize user's experience and to allow us to deliver the type of content and product
                        offerings in which you are most interested.
                    </li>
                    <li class="text-justify">
                        To improve our website in order to better serve you.
                    </li>
                    <li class="text-justify">
                        To allow us to better service you in responding to your customer service requests.
                    </li>
                    <li class="text-justify">
                        To send periodic emails regarding your order or other products and services.
                    </li>
                </ul>

                <h2 class="title font-weight-medium">How do we protect visitor information?</h2>

                <p class="text-justify">
                    We do not use vulnerability scanning and/or scanning to PCI standards.
                </p>

                <p class="text-justify">
                    Your personal information is contained behind secured networks and is only accessible by a limited
                    number of persons who have special access rights to such systems, and are required to keep the
                    information confidential. In addition, all sensitive/credit information you supply is encrypted via
                    Secure Socket Layer (SSL) technology.
                </p>

                <p class="text-justify">
                    We implement a variety of security measures when a user enters, submits, or accesses their
                    information
                </p>

                <p class="text-justify">
                    All transactions are processed through a gateway provider and are not stored or processed on our
                    servers.
                </p>


                <h2 class="title font-weight-medium">Do we use 'cookies'?</h2>

                <p class="text-justify">
                    Yes. Cookies are small files that a site or its service provider transfers to your computer's hard
                    drive through your Web browser (if you allow) that enables the site's or service provider's systems
                    to recognize your browser and capture and remember certain information. For instance, we use cookies
                    to help us remember and process the items in your shopping cart. They are also used to help us
                    understand your preferences based on previous or current site activity, which enables us to provide
                    you with improved services. We also use cookies to help us compile aggregate data about site traffic
                    and site interaction so that we can offer better site experiences and tools in the future.
                </p>

                <p class="text-justify">
                    We use cookies to:
                </p>

                <ul class="mb-5">
                    <li class="text-justify">
                        Understand and save user's preferences for future visits.
                    </li>
                    <li class="text-justify">
                        Compile aggregate data about site traffic and site interactions in order to offer better site
                        experiences and tools in the future. We may also use trusted third party services that track
                        this information on our behalf.
                    </li>
                </ul>

                <p class="text-justify">
                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to
                    turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each
                    browser is a little different, so look at your browser's Help menu to learn the correct way to
                    modify your cookies.
                </p>

                <p class="text-justify">
                    If you disable cookies or turn them off, some features will be disabled. It won't affect the user
                    experience that makes your site experience more efficient and some of our services will not function
                    properly.
                </p>

                <p class="text-justify">
                    However, you can still place orders.
                </p>

                <h2 class="title font-weight-medium">Third Party Disclosure</h2>
                <p class="text-justify">
                    No, we do not sell, trade, or otherwise transfer to outside parties your personally identifiable
                    information unless we provide you with advance notice. This does not include website hosting
                    partners and other parties who assist us in operating our website, conducting our business, or
                    servicing you, so long as those parties agree to keep this information confidential. We may also
                    release your information when we believe release is appropriate to comply with the law, enforce our
                    site policies, or protect ours or others' rights, property, or safety.
                </p>

                <p class="text-justify">
                    However, non-personally identifiable visitor information may be provided to other parties for
                    marketing, advertising, or other uses.
                </p>

                <h2 class="title font-weight-medium">Third Party Links</h2>
                <p class="text-justify">
                    Occasionally, at our discretion, we may include or offer third party products or services on our
                    website. These third party sites have separate and independent privacy policies. We therefore have
                    no responsibility or liability for the content and activities of these linked sites. Nonetheless, we
                    seek to protect the integrity of our site and welcome any feedback about these sites.
                </p>

                <h2 class="title font-weight-medium">Google</h2>
                <p class="text-justify">
                    Google's advertising requirements can be summed up by Google's Advertising Principles. They are put
                    in place to provide a positive experience for users.
                </p>

                <p class="text-justify">
                    <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" target="_blank">Google Ads Policy</a>
                </p>

                <p class="text-justify">
                    We have not enabled Google AdSense on our site but we may do so in the future.
                </p>

                <h2 class="title font-weight-medium">COPPA (Children Online Privacy Protection Act)</h2>
                <p class="text-justify">
                    When it comes to the collection of personal information from children under 13, the Children’s
                    Online
                    Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s
                    consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and
                    online services must do to protect children’s privacy and safety online.
                </p>

                <p class="text-justify">
                    We do not specifically market to children under 13.
                </p>

                <h2 class="title font-weight-medium">Fair Information Practices</h2>
                <p class="text-justify">
                    The Fair Information Practices Principles form the backbone of privacy law in the United States and
                    the concepts they include have played a significant role in the development of data protection laws
                    around the globe. Understanding the Fair Information Practice Principles and how they should be
                    implemented is critical to comply with the various privacy laws that protect personal
                    information.
                </p>

                <p class="text-justify">
                    In order to be in line with Fair Information Practices we will take the following responsive action,
                    should a data breach occur:
                </p>

                <p class="text-justify">
                    We will notify the users via email
                </p>

                <ul class="mb-5">
                    <li class="text-justify">
                        Within 7 business days
                    </li>
                </ul>

                <p class="text-justify">
                    We will notify the users via in site notification
                </p>

                <ul class="mb-5">
                    <li class="text-justify">
                        Within 7 business days
                    </li>
                </ul>

                <p class="text-justify">
                    We also agree to the individual redress principle, which requires that individuals have a right to
                    pursue legally enforceable rights against data collectors and processors who fail to adhere to the
                    law. This principle requires not only that individuals have enforceable rights against data users,
                    but also that individuals have recourse to courts or a government agency to investigate and/or
                    prosecute non-compliance by data processors.
                </p>

                <h2 class="title font-weight-medium">Contacting Us</h2>
                <p class="text-justify">
                    If there are any questions regarding this privacy policy you may contact us using the information
                    below.
                </p>

                <p class="text-justify">
                    <router-link to="/contact">https://mygmrs.com/contact</router-link>
                </p>

                <p class="text-justify">
                    <strong>RepeaterFinder, LLC</strong><br>
                    2360 Route 33<br>
                    Suite 112 - 229<br>
                    Robbinsville, NJ 08691-1416<br>
                    United States<br>
                </p>

                <p class="text-right">Last Edited on 2021-12-29</p>

                <p class="text-center">— End —</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR},

        mounted() {
            useTitle('Privacy Policy - myGMRS.com');
        }
    }
</script>
